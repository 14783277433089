var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100 pr-50"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          staticClass: "icon-card text-airline",
          attrs: {
            "icon": "trainIcon",
            "size": "24"
          }
        }), _c('h6', {
          staticClass: "text-airline fw-700 font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('tour.bookingDetail.tourInfo')) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-row', {}, [_c('b-col', {
    staticClass: "fw-800 font-medium-3 mb-50",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.bookingData.tour.tourName) + " ")]), _vm._l(_vm.tourFields, function (item) {
    return _c('b-col', {
      key: item.label,
      staticClass: "my-25",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('BRow', [_c('BCol', {
      staticClass: "fw-600 d-flex-between",
      attrs: {
        "cols": "5",
        "md": "4"
      }
    }, [_c('div', [_vm._v(_vm._s(item.label))]), _vm._v(" : ")]), _c('BCol', {
      staticClass: "text-info fw-700",
      attrs: {
        "cols": "7",
        "md": "8"
      }
    }, [_vm._v(" " + _vm._s(item.value) + " ")])], 1)], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }