import {
  computed, ref,
} from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

import store from '@/store'
import { STATUS_TOUR } from '@/constants/tour'

import useToast from '@useToast'

import tourStoreModule from '../tourStoreModule'

export default function useTourBookingDetailHandle() {
  if (!store.hasModule('app-tour')) {
    store.registerModule('app-tour', tourStoreModule)
  }
  if (!store.hasModule('app-tour')) store.registerModule('app-tour', tourStoreModule)

  const { toastError } = useToast()

  const loading = computed(() => store.getters['app-tour/getLoading'])
  const bookingData = computed(() => store.getters['app-tour/getBookingData'])

  const fetchBookingById = async bookingId => {
    try {
      const res = await store.dispatch('app-tour/getBookingById', bookingId)
      return res
    } catch (error) {
      toastError({
        title: 'messagesList.error',
        content: 'Tải chi tiết vé thất bại',
      })
    }
    return null
  }

  const refetchBookingData = () => {
    fetchBookingById()
  }

  const disabledCopy = ref(false)

  const { copy } = useClipboard()
  function copyText(text) {
    disabledCopy.value = true
    setTimeout(() => {
      disabledCopy.value = false
    }, 1000)

    copy(text)
      .then(() => {
        this.$root.toastSuccess('reservation.sms.copySuccess')
      })
      .catch(() => {
        this.$root.toastError('reservation.sms.copyFail')
      })
  }

  return {
    loading,
    fetchBookingById,
    refetchBookingData,
    disabledCopy,
    copyText,
    bookingData,
  }
}
