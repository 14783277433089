<template lang="">
  <BBadge :variant="variantColor">
    <HStack v-if="timeToDeadline > 0">
      Còn
      <span v-if="Math.floor(timeToDeadline / HOUR)">{{ Math.floor(timeToDeadline / HOUR) }} giờ</span>
      <span v-if="Math.floor((timeToDeadline / MINUTE) % 60)">{{ Math.floor((timeToDeadline / MINUTE) % 60) }} phút</span>
      <span v-if="Math.floor((timeToDeadline / 1000) % 60)">{{ Math.floor((timeToDeadline / 1000) % 60) }} giây</span>
    </HStack>
    <div v-else>
      Đã hết hạn
    </div>
  </BBadge>
</template>
<script>
import {
  computed, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import { BBadge } from 'bootstrap-vue'

export default {
  components: { BBadge },
  props: {
    deadline: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const timeToDeadline = ref()
    const intervalId = ref()

    onMounted(() => {
      timeToDeadline.value = new Date(props.deadline).getTime() - new Date().getTime()

      intervalId.value = setInterval(() => {
        timeToDeadline.value = new Date(props.deadline).getTime() - new Date().getTime()
        if (timeToDeadline.value <= 0) {
          timeToDeadline.value = 0
          clearInterval(intervalId.value)
        }
      }, 1000)
    })

    onUnmounted(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value)
      }
    })

    const MINUTE = 60 * 1000
    const HOUR = 60 * MINUTE
    const variantColor = computed(() => (timeToDeadline.value < 30 * MINUTE) ? 'danger' : ((timeToDeadline.value < 2 * HOUR) ? 'warning' : 'success'))

    return {
      variantColor, timeToDeadline, HOUR, MINUTE,
    }
  },
}
</script>
<style lang="">

</style>
