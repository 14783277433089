<template>
  <b-card
    body-class="py-75 pb-md-1 px-75 px-1"
    class="border-info-2 shadow-card mb-50"
  >
    <Stack class="gap-2">
      <!-- ANCHOR HEADER: bookingCode -->
      <div :class="`d-flex w-100 ${isMobileView ? 'flex-column' : 'flex-row'} gap-2`">
        <div class="d-flex justify-content-start align-items-center gap-2 flex-1">
          <div class="fw-600">
            {{ $t('train.bookingCode') }}:
          </div>
          <div class="d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25 fit-content">
            <span class="font-weight-bolder d-flex-center justify-content-start">
              <b
                class="text-airline font-large-1 text-nowrap"
                :class="`${isMobileView ? 'font-medium-3' : 'font-large-1'}`"
              >
                {{ bookingData.bookingCode }}
              </b>
              <BButton
                v-b-tooltip.hover.window.top="$t('copy')"
                variant="flat-primary"
                class="p-50 rounded-circle ml-25"
                :disabled="disabledCopy"
                @click="copyText(bookingData?.bookingCode)"
              >
                <IAmIcon
                  icon="copy"
                  size="18"
                  color="#2FA4E5"
                />
              </BButton>
            </span>
          </div>
        </div>

        <Stack
          v-if="bookingData.status"
          class="flex-1"
        >
          <div class="d-flex align-items-center gap-2 flex-1">
            <span class="fw-600">
              {{ $t('train.status.title') }}:
            </span>
            <b-badge
              :variant="resolveColorByStatus(bookingData.realStatus)"
              :class="`badge-glow round pt-50 fw-800 text-uppercase ${isMobileView ? 'font-medium-2' : 'font-medium-3'} px-1`"
            >
              {{ $t(`tour.status.${bookingData.realStatus}`) }}
            </b-badge>
          </div>
          <HStack
            v-if="bookingData.status === STATUS_TOUR.REQUEST_PAYMENT"
            class="align-items-center gap-2"
          >
            <HStack
              center
              gap="0"
              class="fw-600"
            >
              <IAmIcon
                icon="infoOutline"
                class="text-danger rounded-circle p-25"
                size="20"
              />
              Sồ tiền cần {{ bookingData.realStatus === STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán' }}:
            </HStack>
            <span :class="`fw-800 text-warning ${isMobileView ? 'text-16px' : 'text-20px'}`">{{ formatCurrency(bookingData.requestPaymentAmount) }}</span>
          </HStack>
        </Stack>
      </div>
      <div class="d-flex justify-content-center flex-column gap-2">
        <div
          v-if="expiredTime && [STATUS_TOUR.RESERVED, STATUS_TOUR.REQUEST_PAYMENT].includes(bookingData.status)"
          class="fw-600"
        >
          {{ $t('train.detail.timeHold') }}:
          <span class="text-danger">
            {{ convertISODateTime(expiredTime).dateTime }}
          </span>

          <TimeCounter :deadline="expiredTime" />
        </div>
        <div
          v-if="bookingData.createdAt"
          class="fw-600"
        >
          {{ $t('train.detail.createdAt') }}:
          {{ convertISODateTime(bookingData.createdAt).dateTime }}
        </div>
      </div>

      <div
        v-if="totalPriceToBePaid > 0 && !['CANCELLED', 'PAID'].includes(bookingData.status) && !bookingData?.totalPriceRefund"
        class="sticky-total-price"
      >
        <IAmCollapseAlert :show="totalPriceToBePaid > 0">
          <div class="d-flex-center text-nowrap">
            <span class="font-weight-bolder text-dark">
              {{ $t('reservation.needPayment') }}:
            </span>
            <span
              class="font-medium-5 fw-700 ml-50"
              style="color: #FF0500"
            >
              {{ formatCurrency(totalPriceToBePaid) || 0 }}
            </span>
          </div>
        </IAmCollapseAlert>
      </div>
    </Stack>
  </b-card>
</template>

<script>
import { BBadge, BButton, BCard } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import moment from 'moment'

import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import { resolveColorByStatus, STATUS_TOUR } from '@/constants/tour'

import useTourBookingDetailHandle from '../useTourBookingDetailHandle'
import TimeCounter from './TimeCounter.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    TimeCounter,
    IAmCollapseAlert: () => import('@/components/IAmCollapseAlert.vue'),
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const {
      copyText, disabledCopy,
    } = useTourBookingDetailHandle()

    const expiredTime = computed(() => moment(props.bookingData.createdAt).add(12, 'hours').format())

    const totalPriceToBePaid = computed(() => Number((props.bookingData?.totalPrice ?? 0) - (props.bookingData?.totalAmountPaid ?? 0) - (props.bookingData?.promotion?.totalAgencyPromotion ?? 0)))
    return {
      copyText,
      disabledCopy,
      convertISODateTime,
      resolveColorByStatus,
      expiredTime,
      totalPriceToBePaid,
      formatCurrency,
      STATUS_TOUR,
    }
  },
}
</script>

<style lang="scss" scoped>

.sticky-total-price {
  display: block;
  position: relative;
  z-index: 2;
  // left: -8%;
  // top: 88%;
}
</style>
