var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BBadge', {
    attrs: {
      "variant": _vm.variantColor
    }
  }, [_vm.timeToDeadline > 0 ? _c('HStack', [_vm._v(" Còn "), Math.floor(_vm.timeToDeadline / _vm.HOUR) ? _c('span', [_vm._v(_vm._s(Math.floor(_vm.timeToDeadline / _vm.HOUR)) + " giờ")]) : _vm._e(), Math.floor(_vm.timeToDeadline / _vm.MINUTE % 60) ? _c('span', [_vm._v(_vm._s(Math.floor(_vm.timeToDeadline / _vm.MINUTE % 60)) + " phút")]) : _vm._e(), Math.floor(_vm.timeToDeadline / 1000 % 60) ? _c('span', [_vm._v(_vm._s(Math.floor(_vm.timeToDeadline / 1000 % 60)) + " giây")]) : _vm._e()]) : _c('div', [_vm._v(" Đã hết hạn ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }