var render = function () {
  var _vm$bookingData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "border-info-2 shadow-card mb-50",
    attrs: {
      "body-class": "py-75 pb-md-1 px-75 px-1"
    }
  }, [_c('Stack', {
    staticClass: "gap-2"
  }, [_c('div', {
    class: "d-flex w-100 ".concat(_vm.isMobileView ? 'flex-column' : 'flex-row', " gap-2")
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center gap-2 flex-1"
  }, [_c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.bookingCode')) + ": ")]), _c('div', {
    staticClass: "d-flex-center border-airline px-1 py-75 bg-light-info rounded-8px my-25 fit-content"
  }, [_c('span', {
    staticClass: "font-weight-bolder d-flex-center justify-content-start"
  }, [_c('b', {
    staticClass: "text-airline font-large-1 text-nowrap",
    class: "".concat(_vm.isMobileView ? 'font-medium-3' : 'font-large-1')
  }, [_vm._v(" " + _vm._s(_vm.bookingData.bookingCode) + " ")]), _c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.window.top",
      value: _vm.$t('copy'),
      expression: "$t('copy')",
      modifiers: {
        "hover": true,
        "window": true,
        "top": true
      }
    }],
    staticClass: "p-50 rounded-circle ml-25",
    attrs: {
      "variant": "flat-primary",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": function click($event) {
        var _vm$bookingData;
        return _vm.copyText((_vm$bookingData = _vm.bookingData) === null || _vm$bookingData === void 0 ? void 0 : _vm$bookingData.bookingCode);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "copy",
      "size": "18",
      "color": "#2FA4E5"
    }
  })], 1)], 1)])]), _vm.bookingData.status ? _c('Stack', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center gap-2 flex-1"
  }, [_c('span', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.status.title')) + ": ")]), _c('b-badge', {
    class: "badge-glow round pt-50 fw-800 text-uppercase ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-3', " px-1"),
    attrs: {
      "variant": _vm.resolveColorByStatus(_vm.bookingData.realStatus)
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tour.status.".concat(_vm.bookingData.realStatus))) + " ")])], 1), _vm.bookingData.status === _vm.STATUS_TOUR.REQUEST_PAYMENT ? _c('HStack', {
    staticClass: "align-items-center gap-2"
  }, [_c('HStack', {
    staticClass: "fw-600",
    attrs: {
      "center": "",
      "gap": "0"
    }
  }, [_c('IAmIcon', {
    staticClass: "text-danger rounded-circle p-25",
    attrs: {
      "icon": "infoOutline",
      "size": "20"
    }
  }), _vm._v(" Sồ tiền cần " + _vm._s(_vm.bookingData.realStatus === _vm.STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán') + ": ")], 1), _c('span', {
    class: "fw-800 text-warning ".concat(_vm.isMobileView ? 'text-16px' : 'text-20px')
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.bookingData.requestPaymentAmount)))])], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex justify-content-center flex-column gap-2"
  }, [_vm.expiredTime && [_vm.STATUS_TOUR.RESERVED, _vm.STATUS_TOUR.REQUEST_PAYMENT].includes(_vm.bookingData.status) ? _c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.timeHold')) + ": "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(_vm.expiredTime).dateTime) + " ")]), _c('TimeCounter', {
    attrs: {
      "deadline": _vm.expiredTime
    }
  })], 1) : _vm._e(), _vm.bookingData.createdAt ? _c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.createdAt')) + ": " + _vm._s(_vm.convertISODateTime(_vm.bookingData.createdAt).dateTime) + " ")]) : _vm._e()]), _vm.totalPriceToBePaid > 0 && !['CANCELLED', 'PAID'].includes(_vm.bookingData.status) && !((_vm$bookingData2 = _vm.bookingData) !== null && _vm$bookingData2 !== void 0 && _vm$bookingData2.totalPriceRefund) ? _c('div', {
    staticClass: "sticky-total-price"
  }, [_c('IAmCollapseAlert', {
    attrs: {
      "show": _vm.totalPriceToBePaid > 0
    }
  }, [_c('div', {
    staticClass: "d-flex-center text-nowrap"
  }, [_c('span', {
    staticClass: "font-weight-bolder text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.needPayment')) + ": ")]), _c('span', {
    staticClass: "font-medium-5 fw-700 ml-50",
    staticStyle: {
      "color": "#FF0500"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceToBePaid) || 0) + " ")])])])], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }