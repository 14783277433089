var render = function () {
  var _vm$bookingData$audit, _vm$bookingData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isEmpty(_vm.bookingData) ? _c('div', {
    staticClass: "d-flex flex-column gap-2 p-1"
  }, [_c('TourBookingDetailHeader', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }), _c('TourBookingDetailContactInfo', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }), _c('TourBookingDetailTripInfo', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }), _c('TourBookingDetailBookingInfo', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  }), _c('TourBookingDetailAuditLogs', {
    attrs: {
      "auditLogs": (_vm$bookingData$audit = (_vm$bookingData = _vm.bookingData) === null || _vm$bookingData === void 0 ? void 0 : _vm$bookingData.auditLogs) !== null && _vm$bookingData$audit !== void 0 ? _vm$bookingData$audit : []
    }
  }), _c('TourBookingDetailFooter', {
    attrs: {
      "bookingData": _vm.bookingData
    }
  })], 1) : _vm.loading ? _c('div', {
    staticClass: "d-flex-center flex-column py-2 my-2"
  }, [_c('span', [_vm._v(" Đang tải dữ liệu booking ")]), _c('b-spinner', {
    staticClass: "mt-1",
    attrs: {
      "variant": "primary"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex flex-column align-items-center my-2"
  }, [_vm._v(" Không tìm thấy dữ liệu booking "), _c('div', {
    staticClass: "mt-1 d-flex-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "pill": ""
    },
    on: {
      "click": function click() {
        return _vm.refetchBookingData();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center px-25"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "undo",
      "size": "20"
    }
  }), _c('span', {
    staticClass: "ml-50"
  }, [_vm._v("Thử lại")])], 1)])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }