var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-tour-booking-request-cancel-ticket",
      "title": "Gửi yêu cầu huỷ",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "ok-variant": "primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "no-enforce-focus": ""
    },
    on: {
      "show": _vm.handleOpenModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient border-right-0",
          attrs: {
            "pill": ""
          },
          on: {
            "click": _vm.confirmRequestCancel
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" Gửi yêu cầu huỷ ")])])];
      }
    }])
  }, [_c('div', [_c('div', {
    staticClass: "mb-3"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ghi chú",
      "label-class": "font-medium-4"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "4",
      "placeholder": "Ghi chú"
    },
    model: {
      value: _vm.note,
      callback: function callback($$v) {
        _vm.note = $$v;
      },
      expression: "note"
    }
  })], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }