<template>
  <AppCollapse
    type="border-info-2 card"
    class="mb-50"
  >
    <AppCollapseItem
      title
      class-header="card-header bg-blue-gradient p-0 m-50"
      class="border-info-2"
    >
      <template #header>
        <div class="d-flex-between w-100 pr-50">
          <div class="d-flex-center justify-content-start gap-1">
            <IAmIcon
              icon="historyAction"
              class="icon-card text-airline"
              size="24"
            />
            <h6 class="text-airline fw-700 font-medium-3 m-0">
              {{ $t('train.detail.auditLog.title') }}
            </h6>
          </div>
        </div>
      </template>
      <div>
        <b-table
          class="position-relative"
          :items="auditLogs"
          :fields="tableColumns"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          small
          bordered
          style="max-height: 65vh"
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]
          >
            <span
              :key="column.key"
              class="text-nowrap"
            >
              {{ $t(`train.detail.auditLog.columns.${column.label}`) }}
            </span>
          </template>

          <template #table-busy>
            <div class="d-flex-center text-dark my-2 gap-2">
              <b-spinner class="align-middle" />
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>

          <!-- ANCHOR Style for column(s)) -->
          <template #table-colgroup="{ fields }">
            <col
              v-for="field in fields"
              :key="field.key"
              :style="field.style"
            >
          </template>

          <!-- ANCHOR Column orderNo -->
          <template #cell(orderNo)="{ index }">
            {{ auditLogs.length - index }}
          </template>

          <!-- ANCHOR Column date -->
          <template #cell(date)="{ item }">
            {{ convertISODateTime(item.timestamp).dateTimeSecond }}
          </template>

          <!-- ANCHOR Column action -->
          <template #cell(action)="{ item }">
            <Stack center>
              <b-badge
                variant="info"
                class="badge-glow"
              >
                {{ $t(`tour.historyActions.${item.action}`) || getHistoryActionBookingFunction(item.action) }}
              </b-badge>
            </Stack>
          </template>

          <template #cell(amount)="{ item }">
            <div
              v-if="item.action !== 'CREATE'"
              class="font-weight-bold text-truncate"
              style="max-width: 200px;"
            >
              <div
                v-if="!isNaN(Number(item.newValue))"
                class="text-danger"
              >
                {{ formatCurrency(Number(item.newValue)) }}
              </div>
              <div v-else-if="item.newValue && JSON.parse(item.newValue)">
                <div v-if="JSON.parse(item.newValue)?.totalPayment">
                  Tổng tiền đã thanh toán:
                  <div class="text-danger text-right fw-700">
                    {{ formatCurrency(JSON.parse(item.newValue)?.totalPayment) }}
                  </div>
                </div>
                <div v-if="JSON.parse(item.newValue)?.note">
                  {{ $t('tour.tableColumns.note') }}: {{ JSON.parse(item.newValue)?.note }}
                </div>
                <!-- <pre>
                  <div
                    v-for="(value, key) in JSON.parse(item.newValue)"
                    :key="key"
                  >
                    {{ key }} : {{ !isNaN(Number(value)) ? formatCurrency(Number(value)) : value }}
                  </div>
                </pre> -->
              </div>
            </div>
          </template>

          <!-- ANCHOR Column executor -->
          <template #cell(executor)="{ item }">
            <div v-if="item.modifiedBy">
              {{ getLocaleFullName(item.modifiedBy) }}
            </div>
          </template>
        </b-table>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>
<script>
import { BBadge, BTable } from 'bootstrap-vue'
import isNaN from 'lodash/isNaN'

import {
  INVOICE_ACTIONS_HISTORY_TABLE_COLUMNS as tableColumns,
} from '@/constants/invoice'
import { getLocaleFullName } from '@/views/apps/invoices/useInvoice'
import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import store from '@/store'

export default {
  components: {
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    BTable,
    BBadge,
  },
  props: {
    auditLogs: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const getHistoryActionBookingFunction = action => store.getters['globalConfig/getHistoryActionBookingByCode'](action)

    return {
      isNaN,
      tableColumns,
      getLocaleFullName,
      convertISODateTime,
      getHistoryActionBookingFunction,
      formatCurrency,
    }
  },
}
</script>
<style lang="">

</style>
