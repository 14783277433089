<template>
  <AppCollapse
    type="border-info-2 card"
    class="mb-50"
  >
    <AppCollapseItem
      is-visible
      title
      class-header="card-header bg-blue-gradient p-0 m-50"
      class="border-info-2"
    >
      <template #header>
        <div class="d-flex-between w-100 pr-50">
          <div class="d-flex-center justify-content-start gap-1">
            <IAmIcon
              icon="passengers"
              class="icon-card text-airline"
              size="24"
            />
            <h6 class="text-airline fw-700 font-medium-3 m-0">
              {{ $t('train.detail.bookingInfo.title') }}
            </h6>
          </div>

          <div class="d-flex-center mr-2">
            <b-button
              v-b-tooltip.hover.window
              variant="flat-primary"
              class="p-50 rounded-circle mx-25"
              :title="`${$t(`tour.${showCommission ? 'show' : 'hide'}Commission`)}`"
              @click.prevent.stop="showCommission = !showCommission"
            >
              <img
                v-if="showCommission"
                src="@icons/money.svg"
              >
              <img
                v-else
                src="@icons/not_money.svg"
              >
            </b-button>
          </div>
        </div>
      </template>

      <div>
        <b-table
          class="position-relative bg-white"
          :items="priceItems"
          responsive
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          hover
          bordered
          :fields="tableColumns"
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]
          >
            <span
              :key="column.key"
              class="text-nowrap d-flex-center"
            >
              {{ $t(`tour.tableColumns.${column.label}`) }}
            </span>
          </template>

          <template #table-busy>
            <div class="d-flex-center text-dark my-2 gap-2">
              <b-spinner class="align-middle" />
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>

          <template #cell(paxType)="{ item }">
            <div class="font-weight-bolder text-nowrap">
              {{ $t(`tour.passenger.${item.paxType}`) }}
            </div>
          </template>

          <template #cell(tourPrice)="{ item }">
            <div class="text-nowrap text-right mr-50">
              {{ item.tourPrice }}
            </div>
          </template>

          <template #cell(paxQuantity)="{ item }">
            <div class="text-nowrap text-center">
              {{ item.paxQuantity }}
            </div>
          </template>

          <template #cell(totalCommission)="{ item }">
            <div class="text-nowrap text-success text-right mr-50">
              {{ item.totalCommission }}
            </div>
          </template>

          <template #cell(totalPrice)="{ item }">
            <div class="text-nowrap text-right mr-50 fw-700">
              {{ item.totalPrice }}
            </div>
          </template>
        </b-table>

        <div :class="`d-flex-between ${isMobileView ? 'flex-column-reverse align-items-end' : 'flex-row'} w-100`">
          <div
            v-if="showCommission"
            :class="`${isMobileView ? 'px-2 py-0' : 'px-1 py-50'} rounded-lg d-flex-center fit-content gap-2`"
            :style="`border: 1px solid ${showCommission && !isMobileView ? '#E6E6E6' : 'transparent'}; ${isMobileView ? '' : 'height: 64px;'}`"
          >
            <div :class="`d-flex-center gap-2`">
              <span class="fw-600">{{ $t('tour.tableColumns.totalCommission') }}: </span>
              <span :class="`text-${showCommission > 0 ? 'success' : 'danger'} text-20px fw-700`">
                {{ formatCurrency(totalCommission) }}
              </span>
            </div>
          </div>
          <div v-else />
          <Stack :class="`${isMobileView ? 'w-100 align-items-end' : ''}`">
            <HStack
              end
              class="bg-light-primary px-2 py-25 gap-2 rounded-lg fit-content"
            >
              <div class="fw-600 text-18px">
                {{ $t('train.detail.bookingInfo.columns.totalPrice') }}:
              </div>
              <div class="fw-700 text-24px">
                {{ formatCurrency(totalPriceTicket) }}
              </div>
            </HStack>
            <HStack
              end
              class="text-nowrap gap-2 mr-2"
            >
              <div class="fw-600">
                {{ $t('tour.tableColumns.depositPrice') }}:
              </div>
              <div class="fw-700 text-18px">
                {{ formatCurrency(bookingData.totalAmountPaid + bookingData.totalPriceRefund) }}
              </div>
            </HStack>
            <HStack
              end
              class="text-nowrap gap-2 mr-2"
            >
              <div class="fw-600">
                {{ $t('tour.tableColumns.totalPriceRefund') }}:
              </div>
              <div class="fw-700 text-18px text-warning">
                {{ formatCurrency(bookingData.totalPriceRefund) }}
              </div>
            </HStack>
          </Stack>
        </div>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>

<script>
import { BButton, BTable } from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

export default {
  components: {
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    BButton,
    BTable,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const tableColumnsRaw = [
      { key: 'paxType', label: 'paxType' },
      { key: 'tourPrice', label: 'tourPrice' },
      { key: 'paxQuantity', label: 'paxQuantity' },
      { key: 'totalCommission', label: 'totalCommission' },
      { key: 'totalPrice', label: 'totalPrice' },
    ]

    const priceItems = computed(() => {
      const booking = props.bookingData
      if (!booking) return []
      const prices = [
        {
          paxType: 'Adults',
          tourPrice: formatCurrency(booking.adultsPrice),
          paxQuantity: booking.numberAdults ?? 0,
          totalCommission: formatCurrency((booking.promotion?.adultsAgencyPromotion ?? 0) * booking.numberAdults),
          totalPrice: formatCurrency(booking.adultsPrice * booking.numberAdults),
        },
      ]

      if (booking.numberChildren1) {
        prices.push({
          paxType: 'Children1',
          tourPrice: formatCurrency(booking.children1Price),
          paxQuantity: booking.numberChildren1 ?? 0,
          totalCommission: formatCurrency((booking.promotion?.children1AgencyPromotion ?? 0) * booking.numberChildren1),
          totalPrice: formatCurrency(booking.children1Price * booking.numberChildren1),
        })
      }

      if (booking.numberChildren2) {
        prices.push({
          paxType: 'Children2',
          tourPrice: formatCurrency(booking.children2Price),
          paxQuantity: booking.numberChildren2 ?? 0,
          totalCommission: formatCurrency((booking.promotion?.children2AgencyPromotion ?? 0) * booking.numberChildren2),
          totalPrice: formatCurrency(booking.children2Price * booking.numberChildren2),
        })
      }

      if (booking.numberInfant) {
        prices.push({
          paxType: 'Infant',
          tourPrice: formatCurrency(booking.infantPrice),
          paxQuantity: booking.numberInfant ?? 0,
          totalCommission: formatCurrency((booking.promotion?.infantAgencyPromotion ?? 0) * booking.numberInfant),
          totalPrice: formatCurrency(booking.infantPrice * booking.numberInfant),
        })
      }

      return prices
    })

    const showCommission = ref(true)
    const totalCommission = computed(() => props.bookingData?.promotion?.totalAgencyPromotion ?? 0)

    const totalPriceTicket = computed(() => props.bookingData?.totalPrice)

    const tableColumns = computed(() => showCommission.value ? tableColumnsRaw : tableColumnsRaw.filter(t => t.key !== 'totalCommission'))
    return {
      formatCurrency,
      showCommission,
      totalCommission,
      totalPriceTicket,
      priceItems,
      tableColumns,
      convertISODateTime,
    }
  },
}
</script>
