<template>
  <div>
    <div
      v-if="!isEmpty(bookingData)"
      class="d-flex flex-column gap-2 p-1"
    >
      <TourBookingDetailHeader :bookingData="bookingData" />
      <TourBookingDetailContactInfo :bookingData="bookingData" />
      <TourBookingDetailTripInfo :bookingData="bookingData" />
      <TourBookingDetailBookingInfo :bookingData="bookingData" />
      <TourBookingDetailAuditLogs :auditLogs="bookingData?.auditLogs ?? []" />
      <TourBookingDetailFooter :bookingData="bookingData" />
    </div>
    <div
      v-else-if="loading"
      class="d-flex-center flex-column py-2 my-2"
    >
      <span>
        Đang tải dữ liệu booking
      </span>
      <b-spinner
        variant="primary"
        class="mt-1"
      />
    </div>
    <div
      v-else
      class="d-flex flex-column align-items-center my-2"
    >
      Không tìm thấy dữ liệu booking

      <div class="mt-1 d-flex-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          pill
          @click="() => refetchBookingData()"
        >
          <div class="d-flex-center px-25">
            <IAmIcon
              icon="undo"
              size="20"
            />
            <span class="ml-50">Thử lại</span>
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BButton } from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { onUnmounted } from '@vue/composition-api'

import router from '@/router'
import store from '@/store'

import useTourBookingDetailHandle from './useTourBookingDetailHandle'
import TourBookingDetailHeader from './components/TourBookingDetailHeader.vue'
import TourBookingDetailContactInfo from './components/TourBookingDetailContactInfo.vue'
import TourBookingDetailTripInfo from './components/TourBookingDetailTripInfo.vue'
import TourBookingDetailBookingInfo from './components/TourBookingDetailBookingInfo.vue'
import TourBookingDetailAuditLogs from './components/TourBookingDetailAuditLogs.vue'
import TourBookingDetailFooter from './components/TourBookingDetailFooter.vue'

export default {
  components: {
    BSpinner,
    BButton,

    TourBookingDetailHeader,
    TourBookingDetailContactInfo,
    TourBookingDetailTripInfo,
    TourBookingDetailBookingInfo,
    TourBookingDetailAuditLogs,
    TourBookingDetailFooter,
  },
  setup() {
    const {
      fetchBookingById, refetchBookingData, loading, bookingData, copyText, disabledCopy,
    } = useTourBookingDetailHandle()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('app-tour')) store.unregisterModule('app-tour')
    })

    if (router?.currentRoute?.params?.id) {
      fetchBookingById(router.currentRoute.params.id)
    } else {
      console.error('err get booking tour')
    }

    return {
      isEmpty,
      refetchBookingData,
      bookingData,
      loading,
      copyText,
      disabledCopy,
    }
  },
}
</script>
<style lang="">
</style>
