<template>
  <AppCollapse
    type="border-info-2 card"
    class="mb-50"
  >
    <AppCollapseItem
      is-visible
      title
      class-header="card-header bg-blue-gradient p-0 m-50"
      class="border-info-2"
    >
      <template #header>
        <div class="d-flex-between w-100 pr-50">
          <div class="d-flex-center justify-content-start gap-1">
            <IAmIcon
              icon="trainIcon"
              class="icon-card text-airline"
              size="24"
            />
            <h6 class="text-airline fw-700 font-medium-3 m-0">
              {{ $t('tour.bookingDetail.tourInfo') }}
            </h6>
          </div>
        </div>
      </template>

      <b-row class="">
        <b-col
          cols="12"
          class="fw-800 font-medium-3 mb-50"
        >
          {{ bookingData.tour.tourName }}
        </b-col>

        <b-col
          v-for="(item) of tourFields"
          :key="item.label"
          cols="12"
          md="6"
          class="my-25"
        >
          <BRow>
            <BCol
              cols="5"
              md="4"
              class="fw-600 d-flex-between"
            >
              <div>{{ item.label }}</div>
              :
            </BCol>
            <BCol
              cols="7"
              md="8"
              class="text-info fw-700"
            >
              {{ item.value }}
            </BCol>
          </BRow>
        </b-col>
      </b-row>
    </AppCollapseItem>
  </AppCollapse>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import { convertISODateTime } from '@/@core/utils/filter'

import { defaultDepartPoint } from '../../useTourHandle'

export default {
  components: {
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    BRow,
    BCol,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup(props, { root }) {
    const tourFields = computed(() => props.bookingData ? [
      {
        label: 'Mã tour',
        value: props.bookingData.tour.tourCode,
      },
      {
        label: 'Thời gian',
        value: `${props.bookingData.tour.tourTotalDay}N${props.bookingData.tour.tourTotalNight}Đ`,
      },
      {
        label: 'Địa điểm khởi hành',
        value: defaultDepartPoint,
      },
      {
        label: 'Ngày khởi hành',
        value: convertISODateTime(props.bookingData.startDate).date,
      },
      {
        label: 'Khách sạn',
        value: props.bookingData.price.levelNam,
      },
      {
        label: 'Ngày về',
        value: convertISODateTime(props.bookingData.endDate).date,
      },
      {
        label: 'Phương tiện',
        value: props.bookingData.vehiclesGo === props.bookingData.vehiclesBack
          ? root.$t(`tour.vehiclesType.${props.bookingData.vehiclesGo}`)
          : `${
            props.bookingData.vehiclesGo ? root.$t(`tour.vehiclesType.${props.bookingData.vehiclesGo}`) : ''
          } - ${
            props.bookingData.vehiclesBack ? root.$t(`tour.vehiclesType.${props.bookingData.vehiclesBack}`) : ''
          }`,
      },
    ] : [])

    return {
      tourFields,
    }
  },
}
</script>
<style lang="">

</style>
