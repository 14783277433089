<template>
  <b-modal
    id="modal-tour-booking-request-cancel-ticket"
    title="Gửi yêu cầu huỷ"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    ok-variant="primary"
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    no-enforce-focus
    @show="handleOpenModal"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="cancel()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient border-right-0"
        pill
        @click="confirmRequestCancel"
      >
        <div class="d-flex-center px-25">
          <!-- {{ $t('reservation.payment') }} -->
          Gửi yêu cầu huỷ
        </div>
      </b-button>
    </template>

    <div>
      <div class="mb-3">
        <b-form-group
          label="Ghi chú"
          label-class="font-medium-4"
        >
          <b-form-textarea
            v-model="note"
            rows="4"
            placeholder="Ghi chú"
          />
        </b-form-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton, BModal, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { isEmpty } from 'lodash-es'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import { apiTour } from '@/api'

import useToast from '@useToast'

import useTourBookingDetailHandle from '../useTourBookingDetailHandle'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BFormTextarea,
  },
  props: {
    bookingCode: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { toastError, toastSuccess } = useToast()
    const {
      refetchBookingData,
    } = useTourBookingDetailHandle()

    const note = ref('')

    async function handleOpenModal() {
      note.value = ''
    }

    async function confirmRequestCancel() {
      try {
        this.$bvModal.show('modal-api-loading')
        await apiTour.requestCancelBooking(props.bookingCode, note.value)
        toastSuccess({ title: 'Đã gửi yêu cầu huỷ tour' })
        refetchBookingData()
        this.$bvModal.hide('modal-tour-booking-request-cancel-ticket')
      } catch (error) {
        console.log(error)
        toastError({ title: 'Lỗi gửi yêu cầu huỷ tour' })
      } finally {
        this.$bvModal.hide('modal-api-loading')
      }
    }

    return {
      note,

      isEmpty,
      formatCurrency,
      convertISODateTime,
      handleOpenModal,
      confirmRequestCancel,
    }
  },
}
</script>
