var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100 pr-50"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('IAmIcon', {
          staticClass: "icon-card text-airline",
          attrs: {
            "icon": "passengers",
            "size": "24"
          }
        }), _c('h6', {
          staticClass: "text-airline fw-700 font-medium-3 m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.bookingInfo.title')) + " ")])], 1), _c('div', {
          staticClass: "d-flex-center mr-2"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded-circle mx-25",
          attrs: {
            "variant": "flat-primary",
            "title": "".concat(_vm.$t("tour.".concat(_vm.showCommission ? 'show' : 'hide', "Commission")))
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              _vm.showCommission = !_vm.showCommission;
            }
          }
        }, [_vm.showCommission ? _c('img', {
          attrs: {
            "src": require("@icons/money.svg")
          }
        }) : _c('img', {
          attrs: {
            "src": require("@icons/not_money.svg")
          }
        })])], 1)])];
      },
      proxy: true
    }])
  }, [_c('div', [_c('b-table', {
    staticClass: "position-relative bg-white",
    attrs: {
      "items": _vm.priceItems,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "hover": "",
      "bordered": "",
      "fields": _vm.tableColumns
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column.key,
            staticClass: "text-nowrap d-flex-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("tour.tableColumns.".concat(column.label))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(paxType)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t("tour.passenger.".concat(item.paxType))) + " ")])];
      }
    }, {
      key: "cell(tourPrice)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-nowrap text-right mr-50"
        }, [_vm._v(" " + _vm._s(item.tourPrice) + " ")])];
      }
    }, {
      key: "cell(paxQuantity)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-nowrap text-center"
        }, [_vm._v(" " + _vm._s(item.paxQuantity) + " ")])];
      }
    }, {
      key: "cell(totalCommission)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-nowrap text-success text-right mr-50"
        }, [_vm._v(" " + _vm._s(item.totalCommission) + " ")])];
      }
    }, {
      key: "cell(totalPrice)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-nowrap text-right mr-50 fw-700"
        }, [_vm._v(" " + _vm._s(item.totalPrice) + " ")])];
      }
    }], null, true)
  }), _c('div', {
    class: "d-flex-between ".concat(_vm.isMobileView ? 'flex-column-reverse align-items-end' : 'flex-row', " w-100")
  }, [_vm.showCommission ? _c('div', {
    class: "".concat(_vm.isMobileView ? 'px-2 py-0' : 'px-1 py-50', " rounded-lg d-flex-center fit-content gap-2"),
    style: "border: 1px solid ".concat(_vm.showCommission && !_vm.isMobileView ? '#E6E6E6' : 'transparent', "; ").concat(_vm.isMobileView ? '' : 'height: 64px;')
  }, [_c('div', {
    class: "d-flex-center gap-2"
  }, [_c('span', {
    staticClass: "fw-600"
  }, [_vm._v(_vm._s(_vm.$t('tour.tableColumns.totalCommission')) + ": ")]), _c('span', {
    class: "text-".concat(_vm.showCommission > 0 ? 'success' : 'danger', " text-20px fw-700")
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalCommission)) + " ")])])]) : _c('div'), _c('Stack', {
    class: "".concat(_vm.isMobileView ? 'w-100 align-items-end' : '')
  }, [_c('HStack', {
    staticClass: "bg-light-primary px-2 py-25 gap-2 rounded-lg fit-content",
    attrs: {
      "end": ""
    }
  }, [_c('div', {
    staticClass: "fw-600 text-18px"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.bookingInfo.columns.totalPrice')) + ": ")]), _c('div', {
    staticClass: "fw-700 text-24px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceTicket)) + " ")])]), _c('HStack', {
    staticClass: "text-nowrap gap-2 mr-2",
    attrs: {
      "end": ""
    }
  }, [_c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.tableColumns.depositPrice')) + ": ")]), _c('div', {
    staticClass: "fw-700 text-18px"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.bookingData.totalAmountPaid + _vm.bookingData.totalPriceRefund)) + " ")])]), _c('HStack', {
    staticClass: "text-nowrap gap-2 mr-2",
    attrs: {
      "end": ""
    }
  }, [_c('div', {
    staticClass: "fw-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('tour.tableColumns.totalPriceRefund')) + ": ")]), _c('div', {
    staticClass: "fw-700 text-18px text-warning"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.bookingData.totalPriceRefund)) + " ")])])], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }