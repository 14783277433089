<template>
  <HStack
    center
    class="content-footer py-1 px-0 px-lg-4 gap-2"
  >
    <!-- ANCHOR - Back -->
    <b-button
      class="px-50 px-md-2"
      variant="warning"
      pill
      :to="{ name: 'apps-toursBooking-list' }"
    >
      <span class="text-nowrap text-truncate">
        {{ $t('reservation.back') }}
      </span>
    </b-button>

    <!-- ANCHOR - Mặt vé -->
    <!-- <b-button
      v-if="bookingData"
      class="px-50 px-md-2"
      variant="warning"
      pill
      @click="showPopupTicketFace(bookingData.id)"
    >
      <span class="text-nowrap text-truncate">{{ $t('reservation.exportTicket') }}</span>
    </b-button> -->

    <!-- ANCHOR - Huỷ -->
    <b-button
      v-if="bookingData && [STATUS_TOUR.RESERVED, STATUS_TOUR.REQUEST_PAYMENT].includes(bookingData?.status) && bookingData?.bookingCode"
      class="px-50 px-md-2"
      variant="danger"
      pill
      @click="cancelBookingHandle(bookingData.bookingCode, bookingData.status)"
    >
      <span class="text-nowrap text-truncate">
        <!-- {{ $t('reservation.payment') }} -->
        Huỷ tour
      </span>
    </b-button>

    <!-- ANCHOR - Gửi yêu cầu huỷ -->
    <b-button
      v-if="bookingData && [STATUS_TOUR.DEPOSITED, STATUS_TOUR.PAID].includes(bookingData?.status)"
      class="px-50 px-md-2"
      variant="danger"
      pill
      @click="openModalRequestCancelHandle(bookingData?.status)"
    >
      <span class="text-nowrap text-truncate">
        <!-- {{ $t('reservation.payment') }} -->
        Gửi yêu cầu huỷ
      </span>
    </b-button>

    <!-- ANCHOR - Xác nhận Đặt cọc -->
    <b-button
      v-if="bookingData && [STATUS_TOUR.REQUEST_PAYMENT].includes(bookingData?.status)"
      class="px-50 px-md-2 pulse-success-button"
      variant="success"
      pill
      @click="openModalConfirmPaymentHandle(bookingData)"
    >
      <span class="text-nowrap text-truncate">
        Xác nhận {{ bookingData.realStatus === STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán' }}
      </span>
    </b-button>

    <ModalTourBookingRequestCancel
      v-if="[STATUS_TOUR.DEPOSITED, STATUS_TOUR.PAID].includes(bookingData?.status) && bookingData?.bookingCode"
      :bookingCode="bookingData.bookingCode"
    />
  </HStack>
</template>
<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import Vue from 'vue'
import { computed } from '@vue/composition-api'

import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import { apiTour } from '@/api'
import { STATUS_TOUR } from '@/constants/tour'
import { formatCurrency } from '@/@core/utils/filter'
import store from '@/store'
import i18n from '@/libs/i18n'

import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useToast from '@useToast'

import ModalTourBookingRequestCancel from './ModalTourBookingRequestCancel.vue'
import useTourBookingDetailHandle from '../useTourBookingDetailHandle'

export default {
  components: {
    BButton,
    ModalTourBookingRequestCancel,
  },
  directives: {
    'v-b-tooltip': VBTooltip,
  },
  props: {
    bookingData: {
      type: Object,
      default: null,
    },
  },
  setup(props, { root }) {
    const { toastError, toastSuccess } = useToast()
    const {
      refetchBookingData,
    } = useTourBookingDetailHandle()

    function openModalRequestCancelHandle(status) {
      if (![STATUS_TOUR.DEPOSITED, STATUS_TOUR.PAID].includes(status)) {
        toastError({ title: 'Chỉ có thể Gửi yêu cầu huỷ tour với trạng thái Đã đặt cọc hoặc Đã thanh toán' })
        return
      }
      this.$bvModal.show('modal-tour-booking-request-cancel-ticket')
    }

    function cancelBookingHandle(bookingCode, status) {
      if (![STATUS_TOUR.RESERVED, STATUS_TOUR.REQUEST_PAYMENT].includes(status)) {
        toastError({ title: 'Chỉ có thể huỷ tour với trạng thái Giữ chỗ hoặc Yêu cầu đặt cọc/ thanh toán' })
        return
      }

      this.$bvModal
        .msgBoxConfirm('Xác nhận huỷ tour?', {
          title: this.$t('confirmation'),
          size: 'sm',
          okVariant: 'danger',
          // okTitle: this.$t('confirmation'),
          okTitle: 'Huỷ tour',
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.$bvModal.show('modal-api-loading')
              await apiTour.requestCancelBooking(bookingCode)
              toastSuccess({ title: 'Huỷ tour thành công' })
              refetchBookingData()
            } catch (error) {
              console.log(error)
              toastError({ title: 'Lỗi huỷ tour' })
            } finally {
              this.$bvModal.hide('modal-api-loading')
            }
          }
        })
    }
    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])
    async function openModalConfirmPaymentHandle(booking) {
      if (![STATUS_TOUR.REQUEST_PAYMENT, STATUS_TOUR.RESERVED].includes(booking.status)) {
        toastError({ title: 'Chỉ có thể Xác nhận Đặt cọc với trạng thái "Yêu cầu Đặt cọc/Thanh toán"' })
        return
      }

      const isConfirm = await Vue.swal({
        title: `Xác nhận ${booking?.realStatus === STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán'} Tour?`,
        html: `
                  <div class="text-20px font-weight-bolder text-wrap">
                    Bạn đồng ý xác nhận ${booking?.realStatus === STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán'} Tour với số tiền:
                    <span class="text-20px text-dark fw-700">
                      ${formatCurrency(booking.requestPaymentAmount)} VND
                    </span>?
                  </div>`,
        icon: 'question',
        showCancelButton: true,
        showConfirmButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: `Đồng ý ${booking?.realStatus === STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán'}`,
        cancelButtonText: 'Huỷ',
        customClass: {
          confirmButton: 'btn btn-success mx-50',
          cancelButton: 'btn btn-danger mx-50',
          title: 'text-20px font-weight-bolder text-success',
        },
        buttonsStyling: false,
      })

      if (isConfirm?.isConfirmed) {
        let paymentPassword
        if (getMeEnablePaymentPassword.value) {
          paymentPassword = await Vue.swal({
            title: root.$t('myAccount.paymentPassword.title'),
            html: '<div id="i-am-payment-password"></div>',
            didOpen: () => {
              new Vue({
                render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
                i18n,
              }).$mount('#i-am-payment-password')
            },
            focusConfirm: false,
            preConfirm: () => new Promise(resolve => {
              const pwd = document.getElementById('swal-input-payment-password-1').value
              if (!validatorPaymentPassword(pwd)) {
                Vue.swal.showValidationMessage(root.$t('myAccount.paymentPassword.errorInput'))
              } else { resolve(pwd) }
            }),
            showCancelButton: true,
            confirmButtonText: root.$t('confirmation'),
            cancelButtonText: root.$t('cancel'),
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-gradient',
              cancelButton: 'btn btn-danger mr-1',
            },
            allowOutsideClick: false,
            reverseButtons: true,
          })
          if (paymentPassword.isDenied || paymentPassword.isDismissed) {
            return
          }
        }

        try {
          this.$bvModal.show('modal-api-loading')
          const payload = {
            totalPayment: booking.requestPaymentAmount,
            paymentPassword: paymentPassword?.value ?? undefined,
          }
          await apiTour.confirmPaymentBooking(booking.bookingCode, payload)
          toastSuccess({ title: `Xác nhận ${booking.realStatus === STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán'} Tour thành công` })
          refetchBookingData()
        } catch (error) {
          console.log(error)
          toastError({ title: `Lỗi xác nhận ${booking.realStatus === STATUS_TOUR.REQUEST_PAYMENT ? 'Đặt cọc' : 'Thanh toán'} Tour` })
        } finally {
          this.$bvModal.hide('modal-api-loading')
        }
      }
    }

    return {
      STATUS_TOUR,
      openModalRequestCancelHandle,
      cancelBookingHandle,
      openModalConfirmPaymentHandle,
    }
  },
}
</script>
<style lang="scss" scoped>
.content-footer {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}
</style>
